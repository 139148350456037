import React, { FunctionComponent } from "react";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import { ImageInterface } from "../../types/SanityTypes";
import sanityImageUrlBuilder from "../../utils/imageUrlBuilder";
import Section from "../Section";

import "./styles.scss";

interface BannerBlockInterface {
  heading: string;
  blocks: [
    {
      _rawLogo: ImageInterface;
      description: string;
    }
  ];
  palleteColorList?: {
    bgColor: {
      value: string;
      title: string;
    };
  };
}

const BenefitBlock: FunctionComponent<BannerBlockInterface> = props => {
  const { palleteColorList, heading, blocks } = props;
  const bgClassName = palleteColorList?.bgColor?.title || "default";
  const { sanityId, sanityDataset } = useSiteMetadata();
  const urlBuilder = sanityImageUrlBuilder({
    projectId: sanityId,
    dataset: sanityDataset
  });

  return (
    <Section sectionClassName="c-benefit-block" title={heading} theme={bgClassName}>
      {blocks && blocks.length ? (
        <div className="c-benefit-block__item-wrapper">
          {blocks.map((blockItem, index) => {
            return (
              <div className="c-benefit-block__item" key={index}>
                <picture>
                  <img
                    src={
                      urlBuilder
                        .image(blockItem._rawLogo as ImageInterface)
                        .auto("format")
                        .quality(80)
                        .width(50)
                        .height(50)
                        .url() || undefined
                    }
                    alt={(blockItem._rawLogo as ImageInterface).alt as string}
                    className="img-fluid"
                    loading="lazy"
                    width="50px"
                    height="50px"
                  />
                </picture>
                {blockItem.description ? <p className="c-benefit-block__description">{blockItem.description}</p> : null}
              </div>
            );
          })}
        </div>
      ) : null}
    </Section>
  );
};

export default BenefitBlock;
